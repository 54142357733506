<template>
  <v-card>
    <v-card-title
      class="justify-space-between"
      style="padding: 1rem 1.25rem !important; position: fixed; background-color: #fff; width: 100%; z-index: 10;"
    >
      <h3>Penerimaan Barang Supplier</h3>
      <v-btn icon depressed>
        <v-icon @click="onCloseButtonClick()">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <div
      style="padding: 4rem 1.25rem; display: flex; flex-direction: column; gap: 1rem;"
    >
      <v-form ref="supplierChooser">
        <v-row align="start" justify="space-between" class="text-start">
          <v-col cols="4">
            <span class="text-blue">Cari Nomor Pemesanan</span>
            <v-autocomplete
              class="dropdown-item"
              style="margin-top: 1rem;"
              :loading="isLoading"
              dense
              outlined
              placeholder="Pilih Supplier"
              single-line
              :disabled="
                itemsReceive.some(item => item.items.length > 0) ||
                  isConfirmation
              "
              :items="resource.factory"
              item-text="supplierName"
              v-model="input.supplier"
              @change="
                val => {
                  getOrderNumber();
                  handleChangeSupplier(val);
                }
              "
              append-icon="mdi-chevron-down"
              :rules="rules"
              return-object
            />
            <v-autocomplete
              :disabled="input.supplier === '' || isConfirmation"
              dense
              outlined
              placeholder="Pilih Nomor Pemesanan"
              single-line
              :items="resource.orderNumber"
              item-text="orderNumber"
              v-model="input.orderNumber"
              append-icon="mdi-chevron-down"
              :rules="input.supplier !== '' ? rules : []"
              validate-on-blur
              return-object
            />
          </v-col>

          <v-col cols="4" class="px-10">
            <span class="text-blue">Detail Pemesanan</span>
            <div class="label-wrapper" style="margin-top: 1.5rem;">
              <label>Nomor Pemesanan</label>
              <span>
                {{ input.orderNumber ? input.orderNumber.orderNumber : '' }}
              </span>
              <label>Nama Supplier</label>
              <span>
                {{ input.supplier ? input.supplier.supplierName : '' }}
              </span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="label-wrapper" style="margin-top: 3rem;">
              <label>Tanggal Pemesanan</label>
              <span>
                {{ input.orderNumber ? orderDateFormat : '' }}
              </span>
              <label
                v-if="
                  input.receiptInformation.paymentType.toLowerCase() === 'tempo'
                "
                >Tanggal Jatuh Tempo</label
              >
              <span
                v-if="
                  input.receiptInformation.paymentType.toLowerCase() === 'tempo'
                "
              >
                {{ billDueDateFormat }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-form>

      <v-form ref="receivedInformationData">
        <div class="text-start">
          <span class="text-blue">Data Penerimaan Barang</span>
          <div class="wrapper-card" style="margin-top: 1rem;">
            <v-row align="start" justify="space-between" class="text-start">
              <v-col cols="6" class="pr-5">
                <span class="text-title">Informasi Penerimaan</span>
                <div class="input-field mt-4">
                  <label class="required">
                    No Tanda Terima
                  </label>
                  <v-text-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    outlined
                    dense
                    placeholder="Masukkan nomor tanda terima"
                    v-model="input.receiptInformation.receiveNumber"
                    :rules="
                      rules.concat([
                        v => v?.length <= 40 || 'Karaker melebihi ketentuan',
                      ])
                    "
                    counter="40"
                    @blur="checkReceiveNumber()"
                    validate-on-blur
                  />
                </div>
                <div class="input-field">
                  <label class="required">
                    Nomor Invoice/Faktur
                  </label>
                  <v-text-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    outlined
                    dense
                    :rules="
                      rules.concat([
                        v => v?.length <= 40 || 'Karaker melebihi ketentuan',
                      ])
                    "
                    counter="40"
                    placeholder="Masukkan nomor faktur"
                    v-model="input.receiptInformation.invoiceNumber"
                    @blur="checkInvNumber()"
                    validate-on-blur
                  />
                </div>
                <div class="input-field">
                  <label class="required">
                    Tanggal Faktur
                  </label>
                  <Datepicker
                    :max="today"
                    :clickCloseContent="false"
                    :readonly="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    hideDetails
                    showIconCalendar
                    :show="datePickerShow"
                    :date="input.receiptInformation.invoiceDate"
                    :rules="rules"
                    dateFormat="DD MMMM YYYY"
                    @date-change="
                      val =>
                        handleDateChange(
                          val,
                          'receiptInformation',
                          'invoiceDate',
                        )
                    "
                  />
                </div>
                <div class="input-field">
                  <label class="required">
                    Tanggal Penerimaan
                  </label>
                  <Datepicker
                    :clickCloseContent="false"
                    :max="today"
                    :readonly="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    hideDetails
                    showIconCalendar
                    :show="datePickerShow"
                    :date="input.receiptInformation.receiptDate"
                    :rules="rules"
                    dateFormat="DD MMMM YYYY"
                    @date-change="
                      val =>
                        handleDateChange(
                          val,
                          'receiptInformation',
                          'receiptDate',
                        )
                    "
                  />
                </div>
              </v-col>
              <v-divider vertical class="my-4" />
              <v-col cols="6" class="pl-5">
                <span class="text-title">Detail Harga</span>
                <div class="input-field mt-4">
                  <label>
                    Biaya Ongkir
                  </label>
                  <v-currency-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    v-model="input.receiptInformation.shippingCost"
                    class="input-right"
                    outlined
                    dense
                    placeholder="Masukkan biaya ongkir"
                    prefix="Rp"
                  />
                </div>
                <div class="input-field">
                  <label>
                    Biaya Materai
                  </label>
                  <v-currency-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    class="input-right"
                    v-model="input.receiptInformation.stampCost"
                    outlined
                    dense
                    placeholder="Masukkan biaya materai"
                    prefix="Rp"
                  />
                </div>
                <div class="input-field">
                  <label>
                    PPN
                  </label>
                  <v-text-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    v-model.number="ppn"
                    type="number"
                    class="input-right"
                    v-model="input.receiptInformation.vat"
                    outlined
                    dense
                    suffix="%"
                    v-on:keypress="isNumber($event)"
                  />
                </div>
                <div class="input-field">
                  <label class="required">
                    Metode Pembayaran
                  </label>
                  <v-select
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    :items="['Cash', 'Tempo', 'Konsinyasi', 'Hibah']"
                    v-model="input.receiptInformation.paymentType"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    :rules="rules"
                    placeholder="Pilih metode pembayaran"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>

      <div class="wrapper-card text-start" style="margin-top: 1rem;">
        <v-form ref="inputForm">
          <span class="text-title">Form Input Barang</span>
          <v-row
            align="start"
            justify="space-between"
            class="text-start"
            style="margin-top: 0.65rem;"
          >
            <v-col cols="6" class="pr-5">
              <div class="input-field-vertical">
                <label class="required">Nama Barang</label>
                <v-combobox
                  :disabled="input.supplier === '' || input.orderNumber === ''"
                  dense
                  placeholder="Pilih nama barang"
                  outlined
                  single-line
                  :items="filteredItemOrder"
                  item-text="name"
                  v-model="input.items.receivedPerItems.itemName"
                  append-icon="mdi-chevron-down"
                  :rules="rules"
                  validate-on-blur
                  @change="handleChangePerItemName"
                />
              </div>
            </v-col>
            <v-col cols="6" class="pl-5">
              <div class="input-field-vertical">
                <label class="required">Jumlah Pesanan</label>
                <v-text-field
                  outlined
                  :value="
                    `${input.items.receivedPerItems.orderQuantity || ''} ${input
                      .items.receivedPerItems.package || ''}`
                  "
                  disabled
                  single-line
                  dense
                />
              </div>
            </v-col>
          </v-row>

          <table class="table-style">
            <thead>
              <tr>
                <th>No</th>
                <th class="required">Pabrikan Diterima</th>
                <th class="required">Package</th>
                <th class="required">No Batch Barang</th>
                <th class="required">Tanggal Expired</th>
                <th class="required">Jml Diterima</th>
                <th>Jml Bonus/Hibah</th>
                <th class="required text-right">Harga Satuan (HNA)</th>
                <th class="text-center">Diskon</th>
              </tr>
            </thead>
            <tbody>
              <div
                v-for="(item, index) in input.items.receivedPerItems
                  .manufacturers"
                :key="index"
                class="border-bottom"
              >
                <tr>
                  <td style="margin-top: 0.4rem; text-align: center;">
                    {{ index + 1 }}
                  </td>
                  <td>
                    <v-autocomplete
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.manufacturerName"
                      :items="resource.itemStuff.manufactureList"
                      :hide-details="false"
                      single-line
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                      :rules="rules"
                      validate-on-blur
                      item-text="text"
                      item-value="value"
                      return-object
                      :loading="loading.getFactory"
                      placeholder="Pabrikan"
                      @change="
                        val => handleAutoFillUnitPrice(val, index, index2)
                      "
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.packageType"
                      :items="
                        resource.itemStuff.packageList?.find(
                          arr => arr.factory === item.manufacturerName?.text,
                        )?.packaging
                      "
                      :hide-details="false"
                      single-line
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                      :rules="rules"
                      validate-on-blur
                      item-text="value"
                      item-value="value"
                      :loading="loading.getPackage"
                      placeholder="Package"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.batch[0].batchNumber"
                      single-line
                      outlined
                      dense
                      :hide-details="false"
                      :rules="rules"
                      validate-on-blur
                      placeholder="No Batch"
                    />
                  </td>
                  <td>
                    <Datepicker
                      :readonly="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      :clickCloseContent="false"
                      hideDetails
                      showIconCalendar
                      :show="datePickerShow"
                      :date="item.batch[0].expiryDate"
                      :rules="rules"
                      @date-change="
                        val => {
                          item.batch[0].expiryDate = val;
                        }
                      "
                      placeholder="DD/MM/YYYY"
                      dateFormat="DD/MM/YYYY"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.batch[0].receivedQuantity"
                      single-line
                      outlined
                      dense
                      :hide-details="false"
                      :rules="rules"
                      validate-on-blur
                      v-on:keypress="isNumber($event)"
                      placeholder="0"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.batch[0].bonusQuantity"
                      single-line
                      outlined
                      dense
                      :hide-details="false"
                      v-on:keypress="isNumber($event)"
                      placeholder="0"
                    />
                  </td>
                  <td>
                    <v-currency-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-model="item.unitPrice"
                      class="input-right"
                      outlined
                      dense
                      prefix="Rp"
                      :hide-details="false"
                      placeholder="0,00"
                      :rules="currencyRules"
                      validate-on-blur
                    />
                  </td>
                  <td
                    style="display: flex; align-items: start; justify-content: space-between;"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="
                            input.supplier === '' || input.orderNumber === ''
                          "
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          color="#757575"
                          style="min-width: 0 !important; margin-top: 0.1rem;"
                          class="mr-2"
                          @click="
                            () => {
                              item.isDiscountPercentage = !item.isDiscountPercentage;
                              item.discount = null;
                            }
                          "
                        >
                          <v-icon small style="transform: rotate(90deg)">
                            mdi-swap-horizontal
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Ubah mode diskon</span>
                    </v-tooltip>
                    <v-currency-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-if="!item.isDiscountPercentage"
                      v-model="item.discount"
                      class="input-right"
                      outlined
                      dense
                      prefix="Rp"
                      :hide-details="false"
                      placeholder="0,00"
                    />
                    <v-text-field
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      v-else
                      suffix="%"
                      v-model="item.discount"
                      class="input-right"
                      outlined
                      dense
                      :hide-details="false"
                      placeholder="0"
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="
                            input.supplier === '' || input.orderNumber === ''
                          "
                          @click="addItem(index, 0)"
                          small
                          outlined
                          color="primary"
                          class="mx-2"
                          style="min-width: 0 !important; margin-top: 0.1rem; padding: 0 8px;"
                        >
                          <v-icon small>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Tambahkan Batch baru
                      </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="removeItem(index, 0)"
                          :disabled="
                            input.items.receivedPerItems.manufacturers
                              .length === 1
                          "
                          small
                          icon
                          color="#757575"
                          style="min-width: 0 !important; margin-top: 0.1rem;"
                        >
                          <v-icon small>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Hapus data Batch
                      </span>
                    </v-tooltip>
                  </td>
                </tr>
                <div v-for="(arr, index2) in item.batch" :key="index2">
                  <tr v-if="item.batch.length > 1 && index2 !== 0">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <v-text-field
                        v-model="arr.batchNumber"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        :rules="rules"
                        placeholder="No Batch"
                      />
                    </td>
                    <td>
                      <Datepicker
                        :clickCloseContent="false"
                        hideDetails
                        showIconCalendar
                        :show="datePickerShow"
                        :date="arr.expiryDate"
                        :rules="rules"
                        @date-change="
                          val => {
                            arr.expiryDate = val;
                          }
                        "
                        placeholder="DD/MM/YYYY"
                        dateFormat="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="arr.receivedQuantity"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        :rules="rules"
                        v-on:keypress="isNumber($event)"
                        placeholder="0"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="arr.bonusQuantity"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        v-on:keypress="isNumber($event)"
                        placeholder="0"
                      />
                    </td>
                    <td></td>
                    <td
                      style="display: flex; align-items: start; justify-content: right;"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="addItem(index, index2)"
                            small
                            outlined
                            color="primary"
                            class="mx-2"
                            style="min-width: 0 !important; margin-top: 0.1rem; padding: 0 8px;"
                          >
                            <v-icon small>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Tambahkan Batch baru</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="removeItem(index, index2)"
                            small
                            icon
                            color="#757575"
                            style="min-width: 0 !important; margin-top: 0.1rem;"
                          >
                            <v-icon small>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus data Batch</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </div>
              </div>

              <tr
                class="py-2 border-bottom"
                style="display: flex; justify-content: flex-start; align-items: center;"
              >
                <v-btn
                  @click="addManufacture(true)"
                  depressed
                  text
                  style="min-width: 12rem; font-size: 14px; font-weight: 600; margin-left: 1rem;"
                  color="#1976D2"
                  class="text-none"
                  :disabled="
                    resource.itemStuff.manufactureList.length ===
                      input.items.receivedPerItems.manufacturers.length ||
                      !input.items.receivedPerItems.itemName
                  "
                >
                  <v-icon class="mr-2">
                    mdi-plus
                  </v-icon>
                  Tambah Pabrikan
                </v-btn>
              </tr>
            </tbody>
          </table>
        </v-form>

        <v-row style="margin: 1rem 0;">
          <v-spacer />
          <v-btn
            :disabled="input.supplier === '' || input.orderNumber === ''"
            depressed
            outlined
            tile
            color="#1976D2"
            class="text-none mt-5"
            @click="inputToTable()"
          >
            Tambahkan ke Tabel
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>

        <v-divider class="mb-5" />

        <span class="text-title">Daftar Penerimaan Keseluruhan </span>

        <v-form ref="allInputed">
          <table class="table-style my-3">
            <thead>
              <tr class="all-receipt-list">
                <th></th>
                <th class="required">Pabrikan</th>
                <th class="required">Package</th>
                <th class="required">No Batch</th>
                <th class="required">Tgl Expired</th>
                <th class="required">Jml Diterima</th>
                <th>Jml Bonus/Hibah</th>
                <th class="required">Harga Satuan (HNA)</th>
                <th class="text-right">Diskon</th>
                <th class="text-right">Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <div
                v-if="input.items.receivedItems.length === 0"
                style="display: flex; justify-content: center; align-items: center; font-size: 14px; font-weight: 400; color: #757575; height: 4rem;"
              >
                <span>Belum ada data yang ditambahkan</span>
              </div>
              <div
                v-else
                v-for="(item, index) in input.items.receivedItems"
                :key="index"
              >
                <div
                  style="
                  background-color: #F0F8FE; 
                  display: flex; 
                  justify-content: flex-start; 
                  align-items: center; 
                  gap: 2rem; 
                  font-weight: 400;
                  font-size: 12px;
                  color: #404040;
                  height: 3rem;
                  padding-left: 1rem;
                  "
                >
                  <div>{{ index + 1 }}</div>
                  <div>
                    {{ item.itemName.name }}
                  </div>
                  <div>
                    Jumlah Pesanan : {{ item.orderQuantity }} {{ item.package }}
                  </div>
                  <div>
                    <v-btn
                      @click="deleteItemReceive(index)"
                      small
                      icon
                      color="#757575"
                      style="min-width: 0 !important;"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div
                  v-for="(manufacture, index2) in item.manufacturers"
                  :key="index2"
                >
                  <tr class="all-receipt-list">
                    <td></td>
                    <td>
                      <v-autocomplete
                        v-model="manufacture.manufacturerName"
                        :items="item.resource.manufactureList"
                        :hide-details="false"
                        single-line
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :rules="rules"
                        validate-on-blur
                        item-text="text"
                        item-value="value"
                        return-object
                        placeholder="Pabrikan"
                        @change="
                          val => handleAutoFillUnitPrice(val, index, index2)
                        "
                      />
                    </td>
                    <td>
                      <v-autocomplete
                        v-model="manufacture.packageType"
                        :items="
                          item.resource.packageList.find(
                            arr =>
                              arr.factory ===
                              manufacture.manufacturerName?.text,
                          )?.packaging
                        "
                        :hide-details="false"
                        single-line
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        :rules="rules"
                        validate-on-blur
                        item-text="text"
                        item-value="value"
                        placeholder="Package"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="manufacture.batch[0].batchNumber"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        :rules="rules"
                        placeholder="No Batch"
                      />
                    </td>
                    <td>
                      <Datepicker
                        :clickCloseContent="false"
                        hideDetails
                        :show="datePickerShow"
                        :date="manufacture.batch[0].expiryDate"
                        :rules="rules"
                        @date-change="
                          val => {
                            manufacture.batch[0].expiryDate = val;
                          }
                        "
                        placeholder="DD/MM/YYYY"
                        dateFormat="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="manufacture.batch[0].receivedQuantity"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        :rules="rules"
                        v-on:keypress="isNumber($event)"
                        placeholder="0"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="manufacture.batch[0].bonusQuantity"
                        single-line
                        outlined
                        dense
                        :hide-details="false"
                        v-on:keypress="isNumber($event)"
                        placeholder="0"
                      />
                    </td>
                    <td>
                      <v-currency-field
                        v-model="manufacture.unitPrice"
                        class="input-right"
                        outlined
                        dense
                        prefix="Rp"
                        :hide-details="false"
                        placeholder="0,00"
                        :rules="currencyRules"
                        validate-on-blur
                      />
                    </td>
                    <td
                      style="display: flex; align-items: start; justify-content: space-between;"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            icon
                            color="#757575"
                            @click="
                              () => {
                                manufacture.isDiscountPercentage = !manufacture.isDiscountPercentage;
                                manufacture.discount = null;
                              }
                            "
                          >
                            <v-icon small style="transform: rotate(90deg)">
                              mdi-swap-horizontal
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ubah mode diskon</span>
                      </v-tooltip>
                      <v-currency-field
                        v-if="!manufacture.isDiscountPercentage"
                        v-model="manufacture.discount"
                        class="input-right"
                        outlined
                        dense
                        prefix="Rp"
                        :hide-details="false"
                        placeholder="0,00"
                      />
                      <v-text-field
                        v-else
                        suffix="%"
                        v-model="manufacture.discount"
                        class="input-right"
                        outlined
                        dense
                        :hide-details="false"
                        placeholder="0"
                      />
                    </td>
                    <td class="mt-2 text-right">
                      Rp
                      {{
                        formattedCurrency(
                          calculatePerManufacture(manufacture, index, index2),
                        )
                      }}
                    </td>
                    <td
                      style="display: flex; justify-content: flex-start; align-items: center;"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="addItem(index, index2, 0)"
                            small
                            outlined
                            color="primary"
                            class="mx-2"
                            style="min-width: 0 !important; margin-top: 0.1rem; padding: 0 8px;"
                          >
                            <v-icon small>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Tambahkan Batch baru</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="removeItem(index, index2, 0)"
                            :disabled="
                              input.items.receivedItems[index].manufacturers
                                .length === 1
                            "
                            small
                            icon
                            color="#757575"
                            style="min-width: 0 !important; margin-top: 0.1rem;"
                          >
                            <v-icon small>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Hapus data Batch</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <div v-for="(arr, index3) in manufacture.batch" :key="index3">
                    <tr
                      class="all-receipt-list"
                      v-if="manufacture.batch.length > 1 && index3 !== 0"
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-text-field
                          v-model="arr.batchNumber"
                          single-line
                          outlined
                          dense
                          :hide-details="false"
                          :rules="rules"
                          placeholder="No Batch"
                        />
                      </td>
                      <td>
                        <Datepicker
                          :clickCloseContent="false"
                          hideDetails
                          :show="datePickerShow"
                          :date="arr.expiryDate"
                          :rules="rules"
                          @date-change="
                            val => {
                              arr.expiryDate = val;
                            }
                          "
                          placeholder="DD/MM/YYYY"
                          dateFormat="DD/MM/YYYY"
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="arr.receivedQuantity"
                          single-line
                          outlined
                          dense
                          :hide-details="false"
                          :rules="rules"
                          v-on:keypress="isNumber($event)"
                          placeholder="0"
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="arr.bonusQuantity"
                          single-line
                          outlined
                          dense
                          :hide-details="false"
                          v-on:keypress="isNumber($event)"
                          placeholder="0"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        style="display: flex; align-items: start; justify-content: left;"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="addItem(index, index2, index3)"
                              small
                              outlined
                              color="primary"
                              class="mx-2"
                              style="min-width: 0 !important; margin-top: 0.1rem; padding: 0 8px;"
                            >
                              <v-icon small>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Tambahkan Batch baru</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="removeItem(index, index2, index3)"
                              small
                              icon
                              color="#757575"
                              style="min-width: 0 !important; margin-top: 0.1rem;"
                            >
                              <v-icon small>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Hapus data Batch</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </div>

                  <v-divider />
                </div>

                <div class="py-2" style="display: flex; align-items: center;">
                  <v-btn
                    @click="addManufacture(false, index)"
                    depressed
                    text
                    style="min-width: 12rem; font-size: 14px; font-weight: 600; margin-left: 1rem;"
                    color="#1976D2"
                    class="text-none"
                    :disabled="
                      item.resource.manufactureList.length ===
                        input.items.receivedItems[index].manufacturers.length
                    "
                  >
                    <v-icon class="mr-2">
                      mdi-plus
                    </v-icon>
                    Tambah Pabrikan
                  </v-btn>
                </div>
              </div>
            </tbody>
          </table>
        </v-form>

        <p class="text-title mt-4 mb-0">Pengembalian Barang</p>
        <v-row style="margin-top: 0.25rem;">
          <v-col cols="8">
            <v-form ref="returnForm">
              <div
                v-for="(item, index) in input.items.return"
                :key="index"
                class="row-return"
              >
                <div class="input-field-vertical">
                  <label>Nama Barang</label>
                  <v-combobox
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    dense
                    placeholder="Pilih Nama"
                    outlined
                    single-line
                    :items="input.items.receivedItems.map(arr => arr.itemName)"
                    item-text="name"
                    v-model="item.itemName"
                    append-icon="mdi-chevron-down"
                    return-object
                  />
                </div>
                <div class="input-field-vertical">
                  <label>Pabrikan</label>
                  <v-combobox
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    dense
                    placeholder="Pilih Pabrikan"
                    outlined
                    single-line
                    :rules="item.itemName ? rules : []"
                    :items="
                      input?.items?.receivedItems
                        ?.find(arr => arr.itemName === item.itemName)
                        ?.manufacturers?.map(arr => arr.manufacturerName)
                    "
                    item-text="text"
                    v-model="item.manufacturerName"
                    append-icon="mdi-chevron-down"
                    return-object
                    @change="val => handleAutoFillUnitPrice(val, index, index2)"
                  />
                </div>
                <div class="input-field-vertical">
                  <label>Jumlah</label>
                  <v-text-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    dense
                    :rules="item.itemName ? rules : []"
                    placeholder="0"
                    outlined
                    single-line
                    v-model="item.amount"
                    v-on:keypress="isNumber($event)"
                  />
                </div>
                <div class="input-field-vertical">
                  <label>Package</label>
                  <v-combobox
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    dense
                    placeholder="Pilih"
                    outlined
                    single-line
                    :rules="item.itemName ? rules : []"
                    :items="
                      input?.items?.receivedItems
                        ?.find(arr => arr.itemName === item.itemName)
                        ?.resource?.packageList.find(
                          arr => arr.factory === item.manufacturerName?.text,
                        )?.packaging
                    "
                    item-text="name"
                    v-model="item.package"
                    append-icon="mdi-chevron-down"
                  />
                </div>
                <div class="input-field-vertical">
                  <label>Alasan</label>
                  <v-text-field
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    dense
                    placeholder="Masukkan Alasan"
                    outlined
                    single-line
                    v-model="item.reason"
                  />
                </div>
                <div
                  style="display: flex; justify-content: flex-start; align-items: center; gap: 0.5rem;"
                >
                  <v-btn
                    :disabled="
                      input.supplier === '' || input.orderNumber === ''
                    "
                    small
                    outlined
                    color="primary"
                    style="min-width: 0 !important; margin-top: 0.25rem; padding: 0 8px;"
                    @click="handleAddReason(index)"
                  >
                    <v-icon small>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="input.items.return.length === 1"
                    small
                    icon
                    color="#757575"
                    style="min-width: 0 !important; margin-top: 0.25rem;"
                    @click="handleRemoveReason(index)"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-form>
            <div class="container-upload">
              <v-row>
                <v-col
                  cols="5"
                  style="display: flex; flex-direction: column; gap: 0.75rem; text-align: start;"
                >
                  <input
                    type="file"
                    id="files"
                    ref="files"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    v-on:change="handleFilesUpload()"
                    v-show="false"
                  />
                  <h4>Upload Bukti Faktur</h4>
                  <div
                    style="display: flex; justify-content: flex-start; align-items: center; gap: 1rem;"
                  >
                    <v-btn
                      :disabled="
                        input.supplier === '' || input.orderNumber === ''
                      "
                      depressed
                      outlined
                      color="#1976D2"
                      class="text-none"
                      width="125"
                      style="height: 34px; "
                      @click="addFiles()"
                    >
                      Pilih File
                    </v-btn>
                  </div>
                  <div>
                    <span>Jenis file : (.pdf), (.jpg), (.png)</span>
                    <br />
                    <span>Ukuran file max. 2 MB</span>
                  </div>
                </v-col>
                <v-col cols="7" class="mt-2 custome-scroll">
                  <div class="upload-style">
                    <div
                      v-for="(arr, fileIndex) in invoices.failedFiles"
                      :key="'file-' + fileIndex"
                    >
                      <v-row
                        class="failed-upload py-2"
                        no-gutters
                        align="center"
                      >
                        <v-col cols="1" class="ml-2">
                          <v-icon
                            v-if="
                              arr.file.type.search(/.(jpeg|jpg|png)/i) !== -1
                            "
                            color="#D32F2F"
                          >
                            mdi-file-image
                          </v-icon>
                          <v-icon
                            v-else-if="arr.file.type.search(/.pdf/i) !== -1"
                            color="#D32F2F"
                          >
                            mdi-file-pdf-box
                          </v-icon>
                          <v-icon v-else class="icon">mdi-file-code</v-icon>
                        </v-col>
                        <v-col cols="9" class="mx-2">
                          <label
                            class="text-left ma-0"
                            style="font-size: 12px; font-weight: 400; color: #616161;"
                          >
                            File
                            <span style="font-weight: 700;">
                              {{ arr.file.name }}
                            </span>
                            gagal diunggah, karena
                            <span style="color: #D32F2F;">{{
                              arr.message
                            }}</span>
                          </label>
                        </v-col>
                        <v-col cols="1">
                          <button
                            class="btn-back"
                            @click="removeWarning(fileIndex, idx)"
                          >
                            <v-icon color="#757575">mdi-close</v-icon>
                          </button>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-for="(item, fileIndex) in invoices.files"
                      class="row-upload"
                      no-gutters
                      align="center"
                      :key="'file-' + fileIndex"
                    >
                      <v-icon class="icon">mdi-file-document</v-icon>
                      <label
                        class="text-left text-truncate"
                        style="width: 100%;"
                      >
                        {{ item.name }}
                      </label>
                      <v-spacer />
                      <v-btn
                        small
                        color="primary"
                        depressed
                        class="text-none"
                        @click="previewFile(item)"
                        outlined
                        style="min-width: 10px !important;"
                      >
                        <v-icon small>mdi-eye</v-icon>
                      </v-btn>
                      <v-btn
                        small
                        color="#757575"
                        depressed
                        class="text-none"
                        icon
                        @click="removeFiles(fileIndex)"
                      >
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-end pt-5">
              <div
                style="width: 20rem; display: flex; flex-direction: column; gap: 1rem;"
              >
                <div class="subtotal">
                  <span>
                    Total
                  </span>
                  <span> Rp {{ formattedCurrency(total) }} </span>
                </div>
                <v-divider />
                <div
                  class="subtotal"
                  style="display: flex; justify-content: space-between; align-items: center; width: 100%; gap: 2rem;"
                >
                  <span>PPN</span>
                  <span style="margin-left: auto; text-align: right;">
                    {{
                      input.receiptInformation.vat
                        ? `${input.receiptInformation.vat}%`
                        : '0%'
                    }}
                  </span>
                  <span
                    >Rp
                    {{
                      formattedCurrency(
                        (total * input.receiptInformation.vat) / 100,
                      )
                    }}</span
                  >
                </div>
                <div class="subtotal">
                  <span>
                    Biaya Ongkir
                  </span>
                  <span>
                    Rp
                    {{
                      formattedCurrency(input.receiptInformation.shippingCost)
                    }}
                  </span>
                </div>
                <div class="subtotal">
                  <span>
                    Biaya Materai
                  </span>
                  <span>
                    Rp
                    {{ formattedCurrency(input.receiptInformation.stampCost) }}
                  </span>
                </div>
                <v-divider />
                <div class="subtotal" style="color: #1976D2; font-weight: 500;">
                  <span>
                    Harga Total
                  </span>
                  <span>
                    Rp
                    {{
                      formattedCurrency(
                        total +
                          input.receiptInformation.shippingCost +
                          input.receiptInformation.stampCost +
                          (total * input.receiptInformation.vat) / 100,
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-card-actions
      style="width: 100%; position: fixed; bottom: 0; background-color: #fff;"
    >
      <v-spacer />
      <v-btn
        class="text-none"
        color="primary"
        :disabled="disable"
        depressed
        width="72"
        @click="addReceiveOrder()"
        >Simpan</v-btn
      >
    </v-card-actions>
    <v-snackbar
      color="error"
      timeout="3000"
      style="cursor: pointer"
      v-model="snackbar"
    >
      Masukkan Metode Pembayaran
      <v-icon @click.native="snackbar = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
    <v-snackbar
      style="cursor: pointer"
      color="error"
      timeout="3000"
      v-model="snackbar2"
    >
      Tidak Ada Data Penerimaan yang Disimpan
      <v-icon @click.native="snackbar2 = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
    <v-snackbar
      style="cursor: pointer"
      color="error"
      timeout="3000"
      v-model="snackbar3"
    >
      {{ errorMessage }}
      <v-icon @click.native="snackbar3 = false" small color="#ffff"
        >mdi-close</v-icon
      >
    </v-snackbar>
  </v-card>
</template>

<script>
import Constant from '@/const';
import moment from 'moment-timezone';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import Swal from 'sweetalert2';
const { scrollToEmptyInput } = require('@/helper');

import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

const axios = require('axios');
const _ = require('lodash');

export default {
  name: 'InputItemReceipt',
  mixins: [formatMixin, alertMixin],
  components: {
    Datepicker,
  },
  props: {
    isConfirmation: {
      type: Boolean,
      default: false,
    },
    spnumber: {
      type: String,
      default: null,
    },
    supplierName: {
      type: String,
      default: null,
    },
    isFromInputItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingUpload: false,
      total: 0,
      errorMessage: '',
      isLoading: false,
      disable: false,
      loading: {
        getFactory: false,
        getPackage: false,
      },
      resource: {
        paymentMethod: [
          { text: 'Cash', value: 'cash' },
          { text: 'Tempo', value: 'tempo' },
          { text: 'Konsinyasi', value: 'konsinyasi' },
          { text: 'Hibah', value: 'hibah' },
        ],
        reason: [
          'Jumlah Barang Tidak Sesuai',
          'Penggantian Nomor Faktur',
          'Belum Ada Nomor Faktur',
        ],
        itemStuff: {
          manufactureList: [],
          packageList: [],
        },
        factory: [],
        orderNumber: [],
        receivedDate: moment().format('DD/MM/YYYY'),
      },
      menu: false,
      snackbar: false,
      snackbar2: false,
      snackbar3: false,
      snackbar4: false,
      today: moment().format(),
      datePickerShow: false,
      input: {
        billDue: '',
        receiptInformation: {
          receiveNumber: null,
          invoiceNumber: null,
          invoiceDate: '',
          receiptDate: '',
          vat: 11,
          shippingCost: null,
          stampCost: null,
          paymentType: '',
        },
        items: {
          receivedPerItems: {
            itemName: '',
            orderQuantity: '',
            package: '',
            manufacturers: [
              {
                manufacturerName: '',
                packageType: '',
                batch: [
                  {
                    batchNumber: '',
                    expiryDate: '',
                    receivedQuantity: null,
                    bonusQuantity: null,
                  },
                ],
                unitPrice: null,
                discount: null,
                isDiscountPercentage: false,
              },
            ],
          },
          receivedItems: [],
          return: [
            {
              itemName: '',
              manufacturerName: '',
              amount: null,
              package: '',
              reason: '',
            },
          ],
        },
        receiveNumber: '',
        invoiceNumber: '',
        barcode: '',
        name: '',
        orderAmount: '',
        receivedAmount: null,
        unmatchedAmount: null,
        returnReason: '',
        lessStuff: false,
        hibah: false,
        batchNumber: '',
        package: '',
        expiredDate: null,
        unitPrice: null,
        discNominal: null,
        discPercent: null,
        supplier: '',
        orderNumber: '',
        invoiceDate: '',
        receiptDate: '',
        vat: 11,
      },
      invoices: {
        files: [],
        failedFiles: [],
      },
      table: {
        headers: [
          { text: 'Nama Barang', value: 'name', class: 'white--text' },
          { text: 'Jumlah Pesan', value: 'orderAmount', class: 'white--text' },
          {
            text: 'Jumlah Diterima',
            value: 'receivedAmount',
            class: 'white--text',
          },
          {
            text: 'Jumlah Barang Kurang',
            value: 'unmatchAmount',
            class: 'white--text',
          },
          {
            text: 'Alasan Pengembalian',
            value: 'returnReason',
            class: 'white--text',
            sortable: false,
          },
          {
            text: 'Tanggal Expired',
            value: 'expiredDate',
            class: 'white--text',
          },
          { text: 'No Batch', value: 'batch', class: 'white--text' },
          {
            text: 'Harga Satuan',
            value: 'unitPrice',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Disc',
            value: 'discount',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Sub Total',
            value: 'subtotal',
            sortable: false,
            class: 'white--text',
          },
          {
            text: 'Hibah',
            value: 'hibah',
            sortable: false,
            class: 'white--text',
          },
          {
            text: '',
            value: 'delete',
            sortable: false,
          },
        ],
      },
      itemsReceive: [],
      rules: [v => !!v || 'Belum diisi'],
      currencyRules: [v => (v && v !== 0 && v !== '0') || 'Belum diisi'],
      temp: {
        supplier: '',
        orderNumber: '',
        perItemName: '',
      },
    };
  },
  mounted() {
    this.getOrder();
  },
  watch: {
    'input.supplier'(newVal, oldVal) {
      this.temp.supplier = oldVal || newVal;
    },
    'input.orderNumber'(newVal, oldVal) {
      this.temp.orderNumber = oldVal || newVal;
      this.input.billDue = newVal.bill_due;
    },
    'input.items.receivedPerItems.itemName'(newVal, oldVal) {
      this.temp.perItemName = oldVal;
      if (!oldVal || !newVal) {
        this.input.items.receivedPerItems.package = newVal.package;
        this.input.items.receivedPerItems.orderQuantity = newVal.quantity;
        if (newVal) {
          if (
            newVal.category.toLowerCase() === 'obat' ||
            newVal.category.toLowerCase() === 'alat kesehatan'
          ) {
            const {
              name,
              strength,
              strength_unit,
              packaging,
            } = newVal.detail_drugs;
            this.handleGetFactoryByItems({
              isDrugs: true,
              name,
              strength,
              unit: strength_unit,
              factory: newVal.name.match(/\(([^)]+)\)/)[1],
            });
            this.handleGetListPackageByItems(packaging, name, true);
          } else {
            const { name, packaging } = newVal?.detail_goods;
            this.handleGetFactoryByItems({
              isDrugs: false,
              name,
              factory: newVal.name.match(/\(([^)]+)\)/)[1],
            });
            this.handleGetListPackageByItems(packaging, name, false);
          }
        }
      }
    },
    'input.unmatchedAmount'(val) {
      if (val > 0) {
        this.input.lessStuff = true;
      } else {
        this.input.lessStuff = false;
      }
    },
    'input.orderAmount'(val) {
      if (val) {
        this.input.unmatchedAmount = this.input.orderAmount;
        this.input.receivedAmount = 0;
      }
    },
    'input.receivedAmount'(val) {
      if (val === '') {
        this.input.unmatchedAmount = this.input.orderAmount;
      }
      if (val) {
        this.input.unmatchedAmount = Math.max(this.input.orderAmount - val, 0);
      }
    },
    'input.discPercent'(val) {
      if (val) {
        this.input.discNominal = (this.input.unitPrice * val) / 100;
      } else {
        this.input.discNominal = 0;
      }
    },
    'input.discNominal'(val) {
      if (val) {
        this.input.discPercent = (val / this.input.unitPrice) * 100;
      } else {
        this.input.discPercent = 0;
      }
    },
    'input.items.receivedItems': {
      handler() {
        this.$nextTick(() => {
          this.total = this.totalAllPriceItems();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    orderDateFormat() {
      return moment(this.input.orderNumber.orderDate, 'DD/MM/YYYY').format(
        'DD MMMM YYYY',
      );
    },
    billDueDateFormat() {
      return this.input.receiptInformation.invoiceDate && this.input.billDue
        ? moment(this.input.receiptInformation.invoiceDate)
            .add(this.input.billDue, 'days')
            .format('DD MMMM YYYY')
        : '';
    },
    subTotal() {
      const total_first =
        this.input.receivedAmount *
        this.input.unitPrice *
        this.input.name.itemsPerPackage;
      const total_disc =
        (this.input.receivedAmount *
          this.input.unitPrice *
          this.input.discPercent *
          this.input.name.itemsPerPackage) /
        100;
      return total_first - total_disc || 0;
    },
    addedItemsId() {
      const addedItems = this.itemsReceive.map(item =>
        item.items.map(x => x._id),
      );
      const addedItemsId = addedItems.flat();
      return addedItemsId;
    },
    filteredItemOrder() {
      if (this.input.orderNumber === '') return [];
      const items = this.input.orderNumber.items.filter(
        item =>
          !this.addedItemsId.some(x => x === item._id) &&
          !this.input.orderNumber.addedItems.some(x =>
            item.id_drugs ? x === item.id_drugs : x === item.id_goods,
          ),
      );
      return items.filter(
        v =>
          !this.input.items.receivedItems.some(arr =>
            v.name.includes(arr.itemName.name),
          ),
      );
    },
    filteredManufactureList() {
      return this.resource.itemStuff.manufactureList.filter(
        item =>
          !this.input.items.receivedPerItems.manufacturers
            .map(arr => arr.manufacturerName)
            .includes(item),
      );
    },
  },
  methods: {
    handleAutoFillUnitPrice(val, index, index2) {
      if (typeof index2 === 'number') {
        this.$set(
          this.input.items.receivedItems[index].manufacturers[index2],
          'unitPrice',
          Number(val.hna),
        );
      } else {
        this.$set(
          this.input.items.receivedPerItems.manufacturers[index],
          'unitPrice',
          Number(val.hna),
        );
      }
    },
    handleChangePerItemName(val) {
      setTimeout(() => {
        if (this.temp.perItemName === '') {
          return;
        }
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah anda yakin untuk mengubah</p><p>Nama Barang ?</p></div>`,
          iconHtml:
            '<span sstyle="color: #f9a825" class="mdi mdi-help-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data Batch yang telah anda masukkan akan</p><p>terhapus</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batal',
        }).then(result => {
          if (result.isConfirmed) {
            this.temp.perItemName = val;
            this.input.items.receivedPerItems.itemName = val;
            this.input.items.receivedPerItems.manufacturers = [
              {
                manufacturerName: '',
                packageType: '',
                batch: [
                  {
                    batchNumber: '',
                    expiryDate: '',
                    receivedQuantity: null,
                    bonusQuantity: null,
                  },
                ],
                unitPrice: null,
                discount: null,
                isDiscountPercentage: false,
              },
            ];
            this.input.items.receivedPerItems.package = val.package;
            this.input.items.receivedPerItems.orderQuantity = val.quantity;
            if (val) {
              if (
                val.category.toLowerCase() === 'obat' ||
                val.category.toLowerCase() === 'alat kesehatan'
              ) {
                const {
                  name,
                  strength,
                  strength_unit,
                  packaging,
                } = val.detail_drugs;

                this.handleGetFactoryByItems({
                  isDrugs: true,
                  name,
                  strength,
                  unit: strength_unit,
                  factory: val.name.match(/\(([^)]+)\)/)[1],
                });
                this.handleGetListPackageByItems(packaging, name, true);
              } else {
                const { name, packaging } = val?.detail_goods;
                this.handleGetFactoryByItems({
                  isDrugs: false,
                  name,
                  factory: val.name.match(/\(([^)]+)\)/)[1],
                });
                this.handleGetListPackageByItems(packaging, name, false);
              }
            }
            return;
          }
          this.input.items.receivedPerItems.itemName = this.temp.perItemName;
          return;
        });
      }, 100);
    },
    handleChangeSupplier(val) {
      if (!val || !this.temp.supplier) return;
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah anda yakin untuk mengubah</p><p>Data Supplier ?</p></div>`,
        iconHtml:
          '<span sstyle="color: #f9a825" class="mdi mdi-help-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data yang telah anda masukkan tidak akan terhapus</p><p>membatalkan perubahan?</p></div>',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          this.temp.supplier = val;
          this.input.supplier = val;
          this.resetReceivedPerItems();
          return;
        }
        this.input.supplier = this.temp.supplier;
        this.input.orderNumber = this.temp.orderNumber;
        return;
      });
    },
    totalAllPriceItems() {
      if (!this.input.items?.receivedItems) return 0;

      return this.input.items.receivedItems.reduce((totalSum, receivedItem) => {
        if (!receivedItem.manufacturers) return totalSum;

        return (
          totalSum +
          receivedItem.manufacturers.reduce(
            (manuSum, manufacturer) =>
              manuSum + (manufacturer.totalPerManufacture || 0),
            0,
          )
        );
      }, 0);
    },
    calculatePerManufacture(manufacture, index, index2) {
      if (!manufacture || !Array.isArray(manufacture.batch)) return 0;

      const totalQuantity = manufacture.batch.reduce(
        (sum, item) => sum + Number(item.receivedQuantity || 0),
        0,
      );

      const totalPrice = Number(manufacture.unitPrice) * totalQuantity;
      const varDiscount = manufacture.discount
        ? Number(manufacture.discount)
        : 0;

      const discount = manufacture.isDiscountPercentage
        ? totalPrice * (varDiscount / 100)
        : varDiscount;

      this.input.items.receivedItems[index].manufacturers[
        index2
      ].totalPerManufacture = totalPrice - discount;
      return totalPrice - discount;
    },
    async handleGetListPackageByItems(val, name, isDrugs) {
      this.loading.getPackage = true;
      try {
        await axios
          .get(
            Constant.apiUrl.concat(
              `/master/${
                isDrugs ? 'drugs' : 'goods'
              }?page=1&itemCount=8&search=${name}&sort=&category=`,
            ),
          )
          .then(response => {
            const { data } = response.data;
            const mappedResponse = data.map(arr => ({
              packaging: arr.detail.packaging.map(x => x.package_unit),
              factory: arr.detail.factory,
            }));
            this.resource.itemStuff.packageList = mappedResponse;
          })
          .finally(() => {
            this.input.items.receivedPerItems.manufacturers[0].packageType = val.map(
              arr => arr.package,
            )[0];
            this.loading.getPackage = false;
          });
      } catch (error) {
        this.loading.getPackage = false;
        return error;
      }
    },
    async handleGetFactoryByItems({ isDrugs, name, strength, unit, factory }) {
      this.loading.getFactory = true;
      let query = '';
      if (isDrugs) {
        query = `item_name=${name}&strength=${strength}&strength_unit=${unit}`;
      } else {
        query = `item_name=${name}`;
      }
      try {
        await axios
          .get(Constant.apiUrl.concat(`/master/factory/dropdown?${query}`))
          .then(response => {
            const { data } = response.data;
            const mappedResponse = data.map(arr => ({
              text: arr.name,
              value: arr._id,
              hna: arr.hna,
            }));
            this.resource.itemStuff.manufactureList = mappedResponse;
            const getAutoFill =
              mappedResponse.find(
                arr => arr.text.toLowerCase() === factory.toLowerCase(),
              ) || mappedResponse[0];
            this.input.items.receivedPerItems.manufacturers[0].manufacturerName = getAutoFill;
            this.input.items.receivedPerItems.manufacturers[0].unitPrice =
              getAutoFill.hna;
          })
          .finally(() => {
            this.loading.getFactory = false;
          });
      } catch (error) {
        this.loading.getFactory = false;
        return error;
      }
    },
    inputToTable() {
      if (!this.$refs.inputForm.validate()) {
        return;
      }
      if (!this.input.items.receivedPerItems.itemName) {
        return;
      }
      const inputedData = this.input.items.receivedPerItems;
      this.input.items.receivedItems.push({
        ...inputedData,
        manufacturers: inputedData.manufacturers.map(arr => ({
          ...arr,
          discount:
            typeof Number(arr.discount) === 'number' ? Number(arr.discount) : 0,
          unitPrice: Number(arr.unitPrice),
        })),
        resource: {
          manufactureList: this.resource.itemStuff.manufactureList,
          packageList: this.resource.itemStuff.packageList,
        },
      });
      this.resetReceivedPerItems();
    },
    resetReceivedPerItems() {
      this.input.items.receivedPerItems = {
        itemName: '',
        orderQuantity: null,
        package: '',
        manufacturers: [
          {
            manufacturerName: '',
            packageType: '',
            batch: [
              {
                batchNumber: '',
                expiryDate: '',
                receivedQuantity: null,
                bonusQuantity: null,
              },
            ],
            unitPrice: null,
            discount: null,
            isDiscountPercentage: false,
          },
        ],
      };
    },
    addManufacture(isPerItems, index) {
      if (isPerItems) {
        this.input.items.receivedPerItems.manufacturers.push({
          isDiscountPercentage: false,
          discount: null,
          batch: [
            {
              batchNumber: '',
              expiryDate: '',
              receivedQuantity: null,
              bonusQuantity: null,
            },
          ],
        });
      } else {
        this.input.items.receivedItems[index].manufacturers.push({
          isDiscountPercentage: false,
          discount: null,
          batch: [
            {
              batchNumber: '',
              expiryDate: '',
              receivedQuantity: null,
              bonusQuantity: null,
            },
          ],
        });
      }
    },
    handleAddReason(index) {
      this.input.items.return.splice(index + 1, 0, {
        itemName: '',
        manufacturerName: '',
        amount: 0,
        package: '',
        reason: '',
      });
    },
    handleRemoveReason(index) {
      this.input.items.return.splice(index, 1);
    },
    addItem(index, index2, index3) {
      if (typeof index3 === 'number') {
        this.input.items.receivedItems[index].manufacturers[
          index2
        ].batch.splice(index3 + 1, 0, {
          batchNumber: '',
          expiryDate: '',
          receivedQuantity: null,
          bonusQuantity: null,
        });
      } else {
        this.input.items.receivedPerItems.manufacturers[index].batch.splice(
          index2 + 1,
          0,
          {
            batchNumber: '',
            expiryDate: '',
            receivedQuantity: null,
            bonusQuantity: null,
          },
        );
      }
    },
    deleteItemReceive(index) {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah anda yakin untuk menghapus</p><p>Barang ?</p></div>`,
        iconHtml:
          '<span sstyle="color: #f9a825" class="mdi mdi-help-circle-outline"></span>',
        html:
          '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data Barang terpilih akan dihapus</p></div>',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'button-confirm',
          cancelButton: 'button-error-blue',
        },
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          this.input.items.receivedItems.splice(index, 1);
        }
        return;
      });
    },
    removeItem(index, index2, index3) {
      if (typeof index3 === 'number') {
        if (
          this.input.items.receivedItems[index].manufacturers[index2].batch
            .length === 1
        ) {
          this.input.items.receivedItems[index].manufacturers.splice(index2, 1);
        } else {
          this.input.items.receivedItems[index].manufacturers[
            index2
          ].batch.splice(index3, 1);
        }
      } else {
        if (
          this.input.items.receivedPerItems.manufacturers[index].batch[index2]
            .batchNumber
        ) {
          Swal.fire({
            title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Apakah anda yakin untuk mengubah Nama Barang?</p></div>`,
            iconHtml:
              '<span sstyle="color: #f9a825" class="mdi mdi-help-circle-outline"></span>',
            html:
              '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Data yang telah anda masukkan di Form Input</p><p> akan terhapus</p></div>',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'button-confirm',
              cancelButton: 'button-error-blue',
            },
            confirmButtonText: 'Yakin',
            cancelButtonText: 'Batal',
          }).then(result => {
            if (result.isConfirmed) {
              if (
                this.input.items.receivedPerItems.manufacturers[index].batch
                  .length === 1
              ) {
                this.input.items.receivedPerItems.manufacturers.splice(
                  index,
                  1,
                );
              } else {
                this.input.items.receivedPerItems.manufacturers[
                  index
                ].batch.splice(index2, 1);
              }
              return;
            }
            return;
          });
        } else {
          if (
            this.input.items.receivedPerItems.manufacturers[index].batch
              .length === 1
          ) {
            this.input.items.receivedPerItems.manufacturers.splice(index, 1);
          } else {
            this.input.items.receivedPerItems.manufacturers[index].batch.splice(
              index2,
              1,
            );
          }
        }
      }
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) {
        return true;
      } else if (char === '.' || char === ',') {
        e.preventDefault();
        return false;
      } else {
        e.preventDefault();
      }
    },
    handleDateChange(updatedDate, parent, variable) {
      this.input[parent][variable] = updatedDate;
    },
    getConfirmationData() {
      this.resource.orderNumber = [];
      this.input.orderNumber = '';
      const index = this.resource.factory.findIndex(
        x => x.supplierName === this.supplierName,
      );
      this.resource.factory[index].detail.forEach(x => {
        this.resource.orderNumber.push({
          addedItems: x.addedItems,
          orderNumber: x.orderNumber,
          id_supplyRequest: x.id_supplyRequest,
          items: x.items,
          orderDate: x.orderDate,
          total: x.total,
        });
      });
      this.input.supplier = this.resource.factory[index];
      this.input.orderNumber = this.resource.orderNumber.filter(
        item => item.orderNumber === this.spnumber,
      )[0];
    },
    deleteItem(item, indexItemsReceive) {
      const index = this.itemsReceive[indexItemsReceive].items.indexOf(item);
      this.itemsReceive[indexItemsReceive].items.splice(index, 1);
    },
    onCloseButtonClick(snackbar) {
      if (snackbar) {
        this.$emit('close-form', snackbar);
      } else {
        if (this.input.supplier) {
          Swal.fire({
            title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Data Penerimaan belum tersimpan</p></div>`,
            iconHtml:
              '<span style="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
            html:
              '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Silahkan kembali dan Simpan terlebih dahulu</p></div>',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'button-confirm',
              cancelButton: 'button-error-blue',
            },
            confirmButtonText: 'Kembali',
            cancelButtonText: 'Keluar',
          }).then(result => {
            if (result.isConfirmed) {
              return;
            } else {
              this.$emit('close-form');
            }
          });
        } else {
          this.$emit('close-form');
        }
      }
    },
    addFiles() {
      this.$refs.files.click();
      1;
    },
    async submitFiles() {
      if (!this.$refs.receivedInformationData.validate()) {
        return;
      }
      if (this.invoices.files.length > 0) {
        this.loadingUpload = true;
        const formData = new FormData();

        for (let i = 0; i < this.invoices.files.length; i++) {
          const file = this.invoices.files[i];

          formData.append(`files`, file);
        }
        formData.append(
          'identifier',
          `${this.input.orderNumber.orderNumber}${this.input.receiptInformation.invoiceNumber}${this.input.receiptInformation.receiveNumber}`,
        );
        formData.append('tag', 'invoicesupplier');
        formData.append('created_by', this.$store.getters.userLoggedIn.id);

        await axios
          .post(Constant.apiUrl.concat('/upload'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            if (response) this.showFillSuccess('Berhasil Mengunggah File');
            this.invoices.files = [];
            this.$emit('get-data');
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.loadingUpload = false;
          });
      }
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files;
      for (let i = 0; i < filesUploaded.length; i++) {
        if (this.verify(filesUploaded[i]) === '') {
          this.invoices.files.push(filesUploaded[i]);
        } else {
          this.invoices.failedFiles.push({
            file: filesUploaded[i],
            message: this.verify(filesUploaded[i]),
          });
        }
      }

      this.$refs.files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    previewFile(file) {
      const url = URL.createObjectURL(file);
      window.open(url);
    },
    removeFiles(fileIndex) {
      this.invoices.files.splice(fileIndex, 1);
    },
    removeWarning(fileIndex) {
      this.invoices.failedFiles.splice(fileIndex, 1);
    },
    checkInvNumber() {
      if (!this.input.invoiceNumber) return;
      axios
        .get(
          Constant.apiUrl.concat(
            `/supply/check/invoice?invoice=${this.input.receiptInformation.invoiceNumber}&supplier=${this.input.supplier.detail[0].id_supplier}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          if (!data) {
            this.errorMessage =
              'Nomor Invoice atau Faktur Sudah Ada pada penerimaan sebelumnya';
            this.snackbar3 = true;
          }
        });
    },
    checkReceiveNumber() {
      if (!this.input.receiveNumber) return;
      axios
        .get(
          Constant.apiUrl.concat(
            `/supply/check/recnumber?receiveNum=${this.input.receiptInformation.receiveNumber}&supplier=${this.input.supplier.detail[0].id_supplier}`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          if (!data) {
            this.errorMessage =
              'Nomor Tanda Terima Sudah Ada pada penerimaan sebelumnya';
            this.snackbar3 = true;
          }
        });
    },
    async addToTable() {
      if (
        this.hasWhiteSpace(
          this.input.receiptInformation.invoiceNumber.trim(),
        ) ||
        this.hasWhiteSpace(this.input.receiptInformation.receiveNumber.trim())
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            'Nomor invoice dan nomor tanda terima tidak boleh mengandung spasi',
        });
        return;
      }
      if (this.$refs.form.validate()) {
        const payload = {
          id_drugs: this.input.name.id_drugs,
          id_goods: this.input.name.id_goods,
          _id: this.input.name._id,
          return_reason: this.input.returnReason,
          amount: {
            unfit_amount: this.input.unmatchedAmount,
            ordered_amount: this.input.orderAmount,
          },
          price: {
            price_per_unit: this.input.unitPrice,
            discount: this.input.discNominal,
            sub_total: this.subTotal,
          },
          isComplete: !this.input.lessStuff,
          isHibah: this.input.hibah,
          history: [
            {
              received_amount: this.input.receivedAmount,
              created_by: this.$store.getters.userLoggedIn.id,
              created_at: moment().format(),
            },
          ],
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
          supplier: this.input.supplier.detail[0].id_supplier,
          name: this.input.name.name,
          invoice_number: this.input.receiptInformation.invoiceNumber.trim(),
          expiry_date: this.input.expiredDate,
          batch_number: this.input.batchNumber,
          package: this.input.package,
          receive_number: this.input.receiptInformation.receiveNumber.trim(),
          received_amount: this.input.receivedAmount,
          barcode: this.input.barcode,
        };

        const item = {
          receiveNumber:
            payload.receive_number || 'Tidak ada nomor tanda terima',
          invoiceNumber: payload.invoice_number || 'Tidak ada nomor faktur',
          items: [],
          pending_reason: [],
          paymentMethod: '',
          deliveryFee: null,
          materai: null,
          invoices: {
            files: [],
            failedFiles: [],
          },
        };

        const drugs = payload.id_drugs
          ? {
              // id_supplyItem: id_supplyItem,
              _id: payload._id,
              id_drugs: payload.id_drugs,
              barcode: payload.barcode,
              name: payload.name,
              supplier: payload.supplier,
              orderAmount: payload.amount.ordered_amount,
              receivedAmount: payload.history[0].received_amount,
              unmatchAmount: payload.amount.unfit_amount,
              returnReason: payload.return_reason,
              expiredDate: payload.expiry_date,
              batch: payload.batch_number,
              unitPrice: payload.price.price_per_unit,
              discount: payload.price.discount,
              subtotal: payload.price.sub_total,
              hibah: payload.isHibah ? 'Ya' : 'Tidak',
              package: payload.package,
            }
          : '';

        const goods = payload.id_goods
          ? {
              // id_supplyItem: id_supplyItem,
              _id: payload._id,
              id_goods: payload.id_goods,
              barcode: payload.barcode,
              name: payload.name,
              supplier: payload.supplier,
              orderAmount: payload.amount.ordered_amount,
              receivedAmount: payload.history[0].received_amount,
              unmatchAmount: payload.amount.unfit_amount,
              returnReason: payload.return_reason,
              expiredDate: payload.expiry_date,
              batch: payload.batch_number,
              unitPrice: payload.price.price_per_unit,
              discount: payload.price.discount,
              subtotal: payload.price.sub_total,
              hibah: payload.isHibah ? 'Ya' : 'Tidak',
              package: payload.package,
            }
          : '';

        if (
          this.itemsReceive.some(
            x =>
              x.receiveNumber === item.receiveNumber ||
              x.invoiceNumber === item.invoiceNumber,
          )
        ) {
          const index = this.itemsReceive.findIndex(
            x => x.receiveNumber === item.receiveNumber,
          );
          this.itemsReceive[index].items.push(payload.id_drugs ? drugs : goods);
        } else {
          item.items.push(payload.id_drugs ? drugs : goods);
          this.itemsReceive.push(item);
        }

        this.input = {
          receiveNumber: payload.receive_number,
          invoiceNumber: payload.invoice_number,
          barcode: '',
          name: '',
          orderAmount: '',
          receivedAmount: null,
          unmatchedAmount: null,
          returnReason: '',
          lessStuff: false,
          hibah: false,
          batchNumber: '',
          package: '',
          expiredDate: null,
          unitPrice: null,
          discNominal: null,
          discPercent: null,
          supplier: this.input.supplier,
          orderNumber: this.input.orderNumber,
        };
        this.$refs.form.resetValidation();
      }
    },
    getOrder() {
      this.isLoading = true;
      axios
        .get(Constant.apiUrl.concat('/supply/request/dropdown'))
        .then(response => {
          const { data } = response.data;
          const listItems = data.map(arr => {
            return {
              addedItems: arr.detail_receive.map(
                item =>
                  item.item[item.item.length - 1]?.id_drugs ||
                  item.item[item.item.length - 1]?.id_goods,
              ),
              id_supplier: arr.id_supplier,
              id_supplyRequest: arr._id,
              supplierName: arr.supplier.name,
              orderNumber: arr.order_number,
              orderDate: moment(arr.order_date).format('DD/MM/YYYY'),
              bill_due: arr.supplier.detail[0].bill_due,
              items: arr.item,
              total: arr.item.reduce((a, { sub_total }) => a + sub_total, 0),
            };
          });

          this.resource.factory = _(listItems)
            .groupBy(x => x.supplierName)
            .map((value, key) => ({
              supplierName: key,
              detail: value,
            }))
            .value();
          // get confirmation data from detail item order
          if (this.isConfirmation) {
            this.getConfirmationData();
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getOrderNumber() {
      this.resource.orderNumber = [];
      this.input.orderNumber = '';
      const index = this.resource.factory.findIndex(
        x => x.supplierName === this.input.supplier.supplierName,
      );
      this.resource.factory[index].detail.forEach(x => {
        this.resource.orderNumber.push({
          addedItems: x.addedItems,
          orderNumber: x.orderNumber,
          id_supplyRequest: x.id_supplyRequest,
          items: x.items,
          orderDate: x.orderDate,
          total: x.total,
          bill_due: x.bill_due,
        });
      });
    },
    hasWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    },
    async addReceiveOrder() {
      if (await !this.$refs.supplierChooser.validate()) {
        scrollToEmptyInput();
        return;
      }
      if (await !this.$refs.receivedInformationData.validate()) {
        scrollToEmptyInput();
        return;
      }
      if (await !this.$refs.allInputed.validate()) {
        scrollToEmptyInput();
        return;
      }
      if (await !this.$refs.returnForm.validate()) {
        scrollToEmptyInput();
        return;
      }

      const {
        receiveNumber,
        invoiceNumber,
        paymentType,
        shippingCost,
        stampCost,
        vat,
      } = this.input.receiptInformation;

      const subTotalPerManufacture = manufacture => {
        if (!manufacture || !Array.isArray(manufacture.batch)) return 0;

        const totalQuantity = manufacture.batch.reduce(
          (sum, item) => sum + (Number(item.receivedQuantity) || 0),
          0,
        );

        const totalPrice = manufacture.unitPrice * totalQuantity;
        const varDiscount = manufacture.discount
          ? Number(manufacture.discount)
          : 0;

        const discount = manufacture.isDiscountPercentage
          ? totalPrice * (varDiscount / 100)
          : varDiscount;
        return totalPrice - discount;
      };

      const discountConverter = ({
        discount,
        unitPrice,
        batch,
        isDiscountPercentage,
      }) => {
        if (!discount || !unitPrice || !batch) return 0;

        const totalReceivedQuantity = batch.reduce(
          (sum, { receivedQuantity }) => sum + (Number(receivedQuantity) || 0),
          0,
        );
        const numericDiscount = Number(discount);
        const totalPrice = unitPrice * totalReceivedQuantity;

        return isDiscountPercentage
          ? totalPrice * (numericDiscount / 100)
          : numericDiscount;
      };

      const returnItems = this.input.items.return
        .filter(arr => arr.itemName.id_drugs || arr.itemName.id_goods)
        .map(arr => ({
          id_supplier: this.input.supplier.detail[0].id_supplier,
          id_factory: arr.manufacturerName.value,
          id_goods: arr.itemName.id_goods,
          id_drugs: arr.itemName.id_drugs,
          unfit_amount: arr.amount ? Number(arr.amount) : 0,
          package: arr.package,
          return_reason: arr.reason,
        }));

      const receivedItems = this.input.items.receivedItems.map(arr => ({
        id_supplier: this.input.supplier.detail[0].id_supplier,
        id_goods: arr.itemName.id_goods,
        id_drugs: arr.itemName.id_drugs,
        ordered_amount: arr.orderQuantity,
        package: arr.package,
        factory: arr.manufacturers.map(arr2 => ({
          id_factory: arr2.manufacturerName.value,
          package: arr2.packageType,
          batch: arr2.batch.map(arr3 => ({
            batch_number: arr3.batchNumber,
            expiry_date: arr3.expiryDate,
            received_amount: arr3.receivedQuantity
              ? Number(arr3.receivedQuantity)
              : 0,
            hibah_amount: arr3.bonusQuantity ? Number(arr3.bonusQuantity) : 0,
          })),
          price: {
            price_per_unit: arr2.unitPrice ? Number(arr2.unitPrice) : 0,
            discount: discountConverter(arr2),
            sub_total: subTotalPerManufacture(arr2),
          },
        })),
        barcode: '',
        unfit_amount: returnItems
          .filter(x =>
            arr.itemName.id_drugs
              ? x.id_drugs === arr.itemName.id_drugs
              : x.id_goods === arr.itemName.id_goods,
          )
          .reduce((a, b) => a + b.unfit_amount, 0),
        return_reason: '',
        hibah_status: false,
        timestamps: {
          created_by: this.$store.getters.userLoggedIn.id,
        },
      }));

      const payload = [
        {
          id_supplyRequest: this.input.orderNumber.id_supplyRequest,
          receive_number: receiveNumber,
          invoice_number: invoiceNumber,
          payment_type: paymentType.toLocaleLowerCase(),
          pending_reason: [],
          isReceived: true,
          cost: {
            shipping_cost: shippingCost,
            total_cost:
              this.total + shippingCost + stampCost + (this.total * vat) / 100,
            stamp_cost: stampCost,
            ppn: vat,
          },
          item: receivedItems,
          item_return: returnItems,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        },
      ];

      const saveData = () => {
        axios
          .post(Constant.apiUrl.concat('/supply/itemlist'), payload)
          .then(res => {
            if (res) {
              this.disable = true;
              this.submitFiles();
              this.onCloseButtonClick({
                show: true,
                text: 'Penerimaan berhasil disimpan',
                color: 'success',
              });
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
            this.onCloseButtonClick({
              show: true,
              text: 'Penerimaan gagal disimpan',
              color: 'error',
            });
          });
      };

      if (this.filteredItemOrder.length !== 0) {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Terdapat barang yang belum ditambahkan?</p></div>`,
          iconHtml:
            '<span style="color: #f9a825" class="mdi mdi-alert-circle-outline"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Pastikan tidak ada barang yang terlewat. Data penerimaan yang sudah disimpan tidak dapat diubah kembali.</p></div>',
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'button-confirm',
            cancelButton: 'button-error-blue',
          },
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Kembali',
        }).then(result => {
          if (!result.isConfirmed) {
            return;
          }
          saveData();
        });
        return;
      } else {
        saveData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custome-scroll {
  overflow: scroll;
  height: 11rem;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 8px;
    background-color: #9e9e9e;
  }
}
.cont-layout {
  height: 83vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 8px;
    background-color: #9e9e9e;
  }
}
.detail-order {
  display: grid;
  grid-template-columns: 30vh 30vh;
  grid-row-gap: 2vh;
}
.input-data {
  display: grid;
  grid-template-columns: 30vh 30vh;
  align-items: center;
  grid-template-rows: repeat(8, 7vh);
  &.center {
    grid-template-rows: repeat(4, 7vh);
  }
}
.reason-style {
  display: grid;
  align-items: baseline;
  grid-template-columns: 30vh 40vh;
  grid-row-gap: 2vh;
}
.failed-upload {
  background-color: #ffebee;
  border: 1px solid #ededed;
  color: #616161;
  border-radius: 5px;
}
::v-deep .v-data-table__wrapper {
  border-radius: 20px;
}
::v-deep .v-data-table-header {
  background-color: #3498db;
}
.data-table {
  ::v-deep tbody {
    background-color: #f0f9ff;
  }
}

.add-to-table-btn {
  margin-top: -90px;
}

.required:after {
  content: ' *';
  color: red;
}

.text-blue {
  font-size: 16px;
  font-weight: 600;
  color: #1976d2;
}

.label-wrapper {
  display: grid;
  grid-template-columns: 13vw 13vw;
  grid-row-gap: 3.5vh;
  label {
    font-weight: 500;
    font-size: 14px;
    color: #404040;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #404040;
  }
}
.wrapper-card {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #fafafa;
  padding: 1.25rem 1rem;
}
.text-title {
  color: #404040;
  font-weight: 600;
  font-size: 16px;
}
.input-field {
  display: grid;
  grid-template-columns: 14vw auto;
  label {
    margin-top: 0.65rem;
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
}
.input-field-vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  label {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
}
.input-right ::v-deep input {
  text-align: right !important;
}
.table-style {
  width: 100%;
  border-spacing: 0;

  thead {
    background-color: #f5f5f5;
    tr {
      display: grid;
      grid-template-columns: 2.43fr 15.54fr 8.83fr 9.73fr 15.54fr 6.85fr 6.85fr 15.54fr 16.67fr;
      grid-template-rows: auto;
      width: 100%;
      &.all-receipt-list {
        grid-template-columns: 2.47fr 15.69fr 9.81fr 8.6fr 8.6fr 8.6fr 8.6fr 10.36fr 10.44fr 9.12fr 7.72fr !important;
      }
      th {
        text-align: start;
        padding: 0.75rem 0.5rem;
        color: #9e9e9e;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  tbody {
    background-color: #ffffff;
    ::v-deep .v-input__slot {
      min-height: 20px !important;
      .v-input__icon {
        height: 18px;
      }
    }
    tr {
      display: grid;
      grid-template-columns: 2.43fr 15.54fr 8.83fr 9.73fr 15.54fr 6.85fr 6.85fr 15.54fr 16.67fr;
      grid-template-rows: auto;
      align-items: start;
      width: 100%;
      &.all-receipt-list {
        grid-template-columns: 2.47fr 15.69fr 9.81fr 8.6fr 8.6fr 8.6fr 8.6fr 10.36fr 10.44fr 9.12fr 7.72fr !important;
      }
      td {
        text-align: start;
        padding: 1rem 0.5rem 0 0.5rem;
        font-weight: 400;
        font-size: 12px;
        color: #404040;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid #e0e0e0;
  }
}
.subtotal {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  color: #616161;
}
.container-upload {
  position: relative;
  background-color: #ffffff;
  padding: 1rem;
  width: 50vw;
  height: 12.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  h4 {
    color: #404040;
    font-size: 16px;
    font-weight: 600;
  }
  span {
    color: #404040;
    font-size: 12px;
    font-weight: 400;
  }
}
.row-return {
  display: grid;
  grid-template-columns: 22.17fr 22.17fr 9.45fr 9.45fr 22.42fr 9.32fr;
  grid-template-rows: auto;
  align-items: center;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;
  ::v-deep .v-input__slot {
    min-height: 20px !important;
    .v-input__icon {
      height: 18px;
    }
  }
}

::v-deep input::placeholder {
  font-size: 12px;
  color: #c2c2c2;
}

.upload-style {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .row-upload {
    padding: 0 0.5rem;
    border-radius: 4px;
    min-height: 42px;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
}

::v-deep .v-list-item .v-list-item__content {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: start;
}
</style>
